<template>
    <!-- 微信支付展示二维码弹窗 -->
    <div class="wxpay-modal">
        <a-modal v-model:visible="show" title="微信支付" :footer="null" width="500px" :closable="false" @cancel="handleClose">
            <div class="wm-cont">
                <div class="qrcode-img">
                    <img :src="qrcode" alt="">
                </div>
                <div class="wm-price">支付金额：{{ money }} 元</div>
                <div class="wm-desc">请使用微信扫一扫，扫描二维码进行支付</div>
                <div class="wm-tips">支付完成前请不要关闭此窗口</div>

                <a-space>
                    <a-button type="primary" @click="paied">我已支付</a-button>
                    <a-button @click="handleClose">不想支付了</a-button>
                </a-space>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    name:'com-wxpay-modal',
    props:{
        money:{
            type:[Number,String]
        },
        qrcode:{
            type:String
        }
    },
    setup(props,context) {
        const state = reactive({
            show:true,
        })

        function handleClose(){
            context.emit('close')
        }

        function paied(){
           context.emit('paied')
        }

        return{
            ...toRefs(state),
            handleClose,
            paied
        }
    },
}
</script>
<style scoped lang="scss">
.wm-cont{
    width: 100%;
    text-align: center;

    .qrcode-img{
        width: 180px;
        height: 180px;
        padding: 12px;
        box-shadow: 1px 2px 10px #bdd8f1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        box-sizing: border-box;

        img{
            width: 160px;
            height: 160px;
        }
    }
    .wm-price{
        font-weight: bold;
        font-size: 18px;
        color: #FF9900;
        margin: 12px 0;
    }
    .wm-desc{
        margin: 6px 0;
        color: #999;
    }
    .wm-tips{
        width: 100%;
        text-align: center;
        border-top: 1px dashed #f4f4f4;
        color: #999;
        padding-top: 12px;
        margin: 12px 0 24px 0;
    }
}
</style>
