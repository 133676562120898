<template>
    <div class="el-content">
        <com-program-renew 
            :program-id="program_id" 
            :trade-no="order_number" 
            :renew-type="is_trail == 1 ?'buy':'renew'"
            jump-path="/miniapp"
        >
        </com-program-renew>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import comProgramRenew from '@/components/pay/com-program-renew.vue'
export default {
    components:{
        comProgramRenew
    },
    setup() {
        const state = reactive({
            program_id:0,
            order_number:"",
            is_trail:0,
        })
        
        const options = useRoute().query
        if( options.id ){
            state.program_id = options.id
            state.is_trail = options.is_trail
        }
        if( options.out_trade_no ){
            state.order_number = options.out_trade_no
        }

        return{
            ...toRefs(state),
        }
    },
}
</script>
<style scoped lang="scss">

</style>